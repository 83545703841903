<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        重置密码
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                append-icon="mdi-lock"
                autocomplete="off"
                name="password"
                :label="$vuetify.lang.t('$vuetify.common.password')"
                :placeholder="$vuetify.lang.t('$vuetify.common.password')"
                type="password"
                :rules="rules.password"
                required
                v-model="formModel.password"
              />
            </v-col>

            <v-col
              cols="12"
            >
              <v-text-field
                append-icon="mdi-lock"
                autocomplete="off"
                name="confirmPassword"
                :label="$vuetify.lang.t('$vuetify.common.confirmPassword')"
                :placeholder="$vuetify.lang.t('$vuetify.common.confirmPassword')"
                type="password"
                :rules="rules.confirmPassword"
                required
                v-model="formModel.confirmPassword"
              />
            </v-col>

            <v-col
              cols="12"
            >
              <v-text-field
                autocomplete="off"
                name="verificationCode"
                :label="$vuetify.lang.t('$vuetify.common.verificationCode')"
                :placeholder="$vuetify.lang.t('$vuetify.common.verificationCode')"
                type="text"
                :rules="rules.verificationCode"
                required
                v-model="formModel.captcha"
              >
                <template v-slot:append-outer>
                  <v-btn
                    :disabled="waitTime < 60"
                    color="primary"
                    depressed
                    @click="handleVerificationCodeClick"
                  >
                    {{ $vuetify.lang.t('$vuetify.common.sendVerificationCode') }}
                    <span
                      v-if="waitTime < 60"
                    >
                      ( {{ waitTime }} )
                    </span>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary darken-1"
          @click="handleSubmit"
        >
           {{$vuetify.lang.t('$vuetify.common.submit')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="success"
      @input="handleSnackbar"
    >
      {{$vuetify.lang.t('$vuetify.common.changeSuccess')}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="handleSnackbar"
        >
          {{$vuetify.lang.t('$vuetify.common.close')}}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'PageLogin',
    data() {
      return {
        waitTime: 60,
        success: false,
        formValid: false,
        formModel: {
          captcha: null,
          password: null,
          confirmPassword: null,
          phone: null
        },
        rules: {
          verificationCode: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.common.verificationCode')])
          ],
          password: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.common.password')])
          ],
          confirmPassword: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.common.confirmPassword')]),
            (v) =>
              v === this.formModel.password || this.$vuetify.lang.t('$vuetify.rule.sameAs', [this.$vuetify.lang.t('$vuetify.common.password')])
          ]
        },
        socialIcons: [
          {
            text: 'Google',
            icon: 'mdi-google'
          },
          {
            text: 'Facebook',
            icon: 'mdi-facebook'
          },
          {
            text: 'Twitter',
            icon: 'mdi-twitter'
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      prefix() {
        return ''
      }
    },
    methods: {
      ...mapActions({
        smsCaptchaSend: 'smsCaptcha/send',
        resetPassword: 'user/resetPassword'
      }),
      login() {

      },

      handleVerificationCodeClick() {
        this.smsCaptchaSend({ captchaType: 'RESET_PASSWORD', phone: this.user.phone}).then( () => {
          this.countNum()
        })
      },

      handleSubmit() {
        if (this.$refs.form.validate()) {
          this.formModel.phone = this.user.phone
          this.resetPassword(this.formModel)
            .then(() => {
              this.success = true
            })
            .catch(() => {
              this.success = false
            })
        }
      },

      countNum() {
        if (this.waitTime === 0) {
          this.waitTime = 60
        } else {
          this.waitTime--
          setTimeout( () => this.countNum(), 1000)
        }
      },

      handleSnackbar () {
        if (this.success) {
          this.success = false
        }
        this.$refs.form.reset()
      }
    }
  }
</script>

<style lang="sass" scoped>
.page-login
  &__card
  max-width: 600px
  margin: 0 auto
</style>
